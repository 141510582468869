var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$vuetify.breakpoint.name === 'lg' ? 'pr-5 pl-5' : 'px-8'},[_c('div',{staticClass:"mt-5"},[_c('Breadcrumbs',{attrs:{"title":"Add New Questions","items":_vm.items}}),_c('Snackbar',{ref:"snackbar"}),_c('div',{staticClass:"mb-8"},[_c('ValidationObserver',{ref:"observer"},[_c('v-form',[_c('div',[_c('v-expansion-panels',{attrs:{"flat":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},_vm._l((_vm.questions),function(item,i){return _c('v-expansion-panel',{key:i,staticClass:"mb-3 rounded-lg",staticStyle:{"border":"1px solid #ddd"},attrs:{"expand":""},model:{value:(_vm.panel),callback:function ($$v) {_vm.panel=$$v},expression:"panel"}},[_c('v-expansion-panel-header',[_c('span',{staticClass:"font-weight-bold text-h6"},[_vm._v(" Number "+_vm._s(i + 1)+" ")]),_c('v-spacer'),_c('div',{staticClass:"text-right"},[_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.confirmDelete(i)}}},[_c('v-icon',{attrs:{"color":"red"}},[_vm._v("mdi-trash-can-outline")])],1)],1)],1),_c('v-divider'),_c('v-expansion-panel-content',[_c('div',{staticClass:"mt-7 mb-3"},[_c('ValidationProvider',{attrs:{"name":item.instruction,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Instructions","autocomplete":"off","color":"#F05326"},model:{value:(item.instruction),callback:function ($$v) {_vm.$set(item, "instruction", $$v)},expression:"item.instruction"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":item.paragraph,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{staticClass:"mb-2",attrs:{"outlined":"","dense":"","rows":"3","persistent-hint":"","hint":"Add formatting to your hints using markdown $[your hints]","error-messages":errors,"label":"Paragraph","autocomplete":"off","color":"#F05326"},on:{"keyup":function($event){return _vm.markdown($event)}},model:{value:(item.paragraph),callback:function ($$v) {_vm.$set(item, "paragraph", $$v)},expression:"item.paragraph"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":item.question,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Question","autocomplete":"off","color":"#F05326"},model:{value:(item.question),callback:function ($$v) {_vm.$set(item, "question", $$v)},expression:"item.question"}})]}}],null,true)})],1),_vm._l((item.options),function(items,index){return _c('div',{key:index},[_c('div',{staticClass:"caption text-right"},[_vm._v("Correct Answer")]),_c('ValidationProvider',{attrs:{"name":item.text,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":("Option " + (index + 1)),"autocomplete":"off","color":"#F05326"},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ValidationProvider',{attrs:{"name":item.selectedValue,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-radio-group',{staticClass:"py-0 pt-0 pa-0",attrs:{"error-messages":errors},model:{value:(item.selectedValue),callback:function ($$v) {_vm.$set(item, "selectedValue", $$v)},expression:"item.selectedValue"}},[_c('v-radio',{attrs:{"color":"#F05326","value":items.text}})],1)]}}],null,true)})]},proxy:true}],null,true),model:{value:(items.text),callback:function ($$v) {_vm.$set(items, "text", $$v)},expression:"items.text"}})]}}],null,true)})],1)}),_c('div',[_c('v-btn',{attrs:{"color":"#F05326","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.addOptions(i)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Options ")],1),_c('v-btn',{staticClass:"float-right",attrs:{"color":"red","text":"","dense":"","small":"","elevation":"0"},on:{"click":function($event){return _vm.clearAnswer(i)}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-close")]),_vm._v(" Clear Answer ")],1)],1),_c('div',{staticClass:"mt-5"},[_c('ValidationProvider',{attrs:{"name":item.correct_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Correct Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(item.correct_explan),callback:function ($$v) {_vm.$set(item, "correct_explan", $$v)},expression:"item.correct_explan"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":item.incorrect_explan,"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","error-messages":errors,"label":"Incorrect Answer Explanation","autocomplete":"off","color":"#F05326"},model:{value:(item.incorrect_explan),callback:function ($$v) {_vm.$set(item, "incorrect_explan", $$v)},expression:"item.incorrect_explan"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":("" + (item.point)),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"outlined":"","dense":"","type":"number","error-messages":errors,"label":"Point","autocomplete":"off","color":"#F05326"},model:{value:(item.point),callback:function ($$v) {_vm.$set(item, "point", $$v)},expression:"item.point"}})]}}],null,true)})],1)],2)],1)}),1)],1),_c('div',{staticClass:"mt-7"},[_c('v-btn',{staticClass:"text-capitalize",attrs:{"outlined":"","dense":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":function($event){return _vm.addQuestion()}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Add Question ")],1),_c('v-btn',{staticClass:"text-capitalize white--text float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F05326"},on:{"click":_vm.setData}},[_vm._v(" Save ")]),_c('v-btn',{staticClass:"text-capitalize black--text mr-5 float-right",attrs:{"dense":"","depressed":"","loading":_vm.$store.state.process.run,"disabled":_vm.$store.state.process.run,"color":"#F5F5F5"},on:{"click":function($event){return _vm.$router.push(("/questions/" + (_vm.$route.params.id_course) + "/" + (_vm.$route.params.id_topic) + "/" + (_vm.$route.params.id_activity) + "/" + (_vm.$route.params.activity_type)))}}},[_vm._v(" Cancel ")])],1)])],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }